import { Component, ViewChild, ElementRef } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // private socket: Socket;
  // @ViewChild('chatContainerScroll', { static: true }) private myScrollContainer: ElementRef;

  title = 'karbon-group-chat-front-end';
  constructor(){
    // this.socket = io(environment.SOCKET_URL);
    // console.log("Socket Server Insiziated");
  }

  ngOnInit() {
    // this.scrollToBottom();
  }

  // ngAfterViewChecked() {
  //   this.scrollToBottom();
  // }

  // scrollToBottom(): void {
  //   try {
  //     console.log("Calling");
      
  //     this.myScrollContainer.nativeElement.scrollBottom = this.myScrollContainer.nativeElement.scrollHeight;
  //   } catch (err) {
  //     console.log(err);

  //   }
  // }
}
