import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'chats',
    pathMatch: 'full',
  },
  {
    path: 'chats',
    children: [
      {
        path: '',
        loadChildren: './chat/chat.module#ChatModule',
      }
    ]
  },
  {
    path: 'login',
    children: [
      {
        path: '',
        loadChildren: './login/login.module#LoginModule'
      }
    ]
  },
  // {
  //   path: 'admin',
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: './admin/admin.module#AdminModule'
  //     }
  //   ]
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
