// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   SOCKET_URL: "http://localhost:4560",
//   API_URL: "http://localhost:4560/api",
//   IMAGE_LOAD_PATH : "http://localhost:4560/uploads/"
// };

export const environment = {
  production: true,
  SOCKET_URL: "https://community.karbonai.com:4560",
  API_URL: "https://community.karbonai.com:4560/api",
  IMAGE_LOAD_PATH : "https://community.karbonai.com:4560/uploads/"
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
